import { render, staticRenderFns } from "./PrivacyPolicyqf.vue?vue&type=template&id=8b182104&scoped=true&"
import script from "./PrivacyPolicyqf.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicyqf.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicyqf.vue?vue&type=style&index=0&id=8b182104&prod&lang=less&"
import style1 from "./PrivacyPolicyqf.vue?vue&type=style&index=1&id=8b182104&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b182104",
  null
  
)

export default component.exports